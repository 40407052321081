<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotReportFirst' }">数据采集</el-breadcrumb-item>
          <el-breadcrumb-item>历史记录</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="search">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="上报时间">
            <el-date-picker
              size="small"
              v-model="formInline.finishTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
          <el-form-item label="标题筛选">
            <el-select size="small" v-model="filter" clearable placeholder="请选择筛选项" @change="changeFilter">
              <!-- <div v-if="row.abilityOrPointTable==0">
                <el-option
                  v-for="fItem in filterRecords"
                  :label="fItem.parameterCn"
                  :value="fItem.parameterEn"
                  :key="fItem.parameterEn"
                ></el-option>
              </div>
              <div v-if="row.abilityOrPointTable==1"> -->
                <el-option
                  v-for="fItem in filterRecords"
                  :label="fItem.describe"
                  :value="fItem.pointName"
                  :key="fItem.pointName"
                ></el-option>
              <!-- </div> -->
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-list" style="margin:0 0 100px;">
        <!-- <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="waterworks" label="水厂">
            <template slot-scope="scope">
              {{ scope.row.waterworks?scope.row.waterworks:'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="position" label="泵房编号">
            <template slot-scope="scope">
              {{ scope.row.position?scope.row.position:'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
          <el-table-column prop="eid" label="设备号"></el-table-column>
          <el-table-column prop="flowInstantaneous" label="瞬时流量（m³/h）">
            <template slot-scope="scope">
              {{ scope.row.content.flowInstantaneous }}
            </template>
          </el-table-column>
          <el-table-column prop="fluidVelocity" label="瞬时流速（m/s）">
            <template slot-scope="scope">
              {{ scope.row.content.fluidVelocity }}
            </template>
          </el-table-column>
          <el-table-column prop="positiveCumulativeFlow" label="正累积流量（m³）">
            <template slot-scope="scope">
              {{ scope.row.content.positiveCumulativeFlow }}
            </template>
          </el-table-column>
          <el-table-column prop="negativeCumulativeFlow" label="负累积流量（m³）">
            <template slot-scope="scope">
              {{ scope.row.content.negativeCumulativeFlow }}
            </template>
          </el-table-column>
          <el-table-column prop="reportTime" label="上报时间">
            <template slot-scope="scope">
              {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
        </el-table> -->
        <!-- <div v-if="row.abilityOrPointTable==0">
          <el-table ref="tables" :data="tableContent" style="width: 100%;overflow: auto;">
            <el-table-column prop="reportTime" label="上报时间" width="240">
              <template slot-scope="scope">
                {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
              </template>
            </el-table-column>
            <el-table-column :class="tItem.parameterEn" :width="tableTitle.length<9?'':'240'" v-for="(tItem, tIndex) in tableTitle" :key="tIndex" :label="`${tItem.parameterCn}`" :prop="tItem.parameterEn"></el-table-column>
          </el-table>
        </div>
        <div v-if="row.abilityOrPointTable==1"> -->
          <el-table ref="tables" :data="tableContent" style="width: 100%;overflow: auto;">
            <el-table-column prop="reportTime" label="上报时间" width="240">
              <template slot-scope="scope">
                {{ scope.row.reportTime ? dayjs(scope.row.reportTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
              </template>
            </el-table-column>
            <el-table-column :class="tItem.pointName" :width="tableTitle.length<9?'':'240'" v-for="(tItem, tIndex) in tableTitle" :key="tIndex" :label="`${tItem.describe}${tItem.pointName.includes('Tag')?tItem.pointName:''}`" :prop="tItem.pointName"></el-table-column>
          </el-table>
        <!-- </div> -->
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
      <div class="bottom-btn">
        <el-button type="primary" size="small" @click="$router.push('/iotReportFirst')">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      formInline: {
        finishTime:[]
      },
      row: {},
      titleArr: [],
      tableTitle: [],
      tableContent: [],
      filterRecords: [],
      filter: '',

      staffId: 0,
      roleId: 0
    }
  },
  methods: {
    dayjs,
    onSubmit() {
      this.page = 1
      sessionStorage.setItem('currentPage', 1)
      this.getRecordList()
    },
    // 巡查转指派
    turnPatrol(row) {
      this.$router.push({ name: 'direcPatrolTurnAdd', params: {row: row}})
    },
    details(row) {
      this.$router.push({ name: 'patrolReportDetail', params: {row: row}})
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.getRecordList()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.getRecordList()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    // 获取历史记录列表
    async getRecordList() {
      await this.loadRecordTableTitle()
      await this.loadRecordTableContent()
    },
    // 获取历史记录表头
    loadRecordTableTitle() {
      return new Promise((resolve,reject)=>{
        this.loading = true
        this.$ajax.post('equipRecordTitle', {
          eid: this.row.eid?this.row.eid:null,
          equipmentBrandCode: this.row.equipmentBrandCode?this.row.equipmentBrandCode:null
        }).then(res => {
          this.tableTitle = res.data.titles
          this.filterRecords = res.data.titles
          this.loading = false
          resolve()
        }).catch(err => {
          this.loading = false
        })
      })
    },

    // 获取历史记录内容
    loadRecordTableContent() {
      return new Promise((resolve,reject)=>{
        this.loading = true
        // 获取昨天的时间戳
        let yDate = new Date()
        let year = yDate.getFullYear();
        let month = yDate.getMonth()+1;
        let date = yDate.getDate()-1;
        let yesterday = year+'-'+month+'-'+date
        let yesterdayTime = (new Date(new Date(yesterday).toLocaleDateString())).getTime();
        // 获取当天的时间戳
        let todayTime = (new Date(new Date().toLocaleDateString())).getTime();
        let dates = [] 
        if(this.formInline.finishTime){
          this.formInline.finishTime.forEach((i)=>{
            var date = new Date(i)
            dates.push(date.getTime()) 
          })
        }
        this.$ajax.post('equipRecordContent', {
          eid: this.row.eid?this.row.eid:null,
          startTime: dates.length>0?dates[0]:null,
          endTime: dates.length>0?dates[1]:null,
          pageNum: this.page,
          pageSize: this.size,
        }).then(res => {
          this.contentArr = []
          res.data.forEach(v=>{
            let obj = {
              reportTime: v.reportTime
            }
            for(let rIndex in v.attributes) {
              obj[rIndex] = v.attributes[rIndex]&&v.attributes[rIndex]!='null'?v.attributes[rIndex]: '-'
            }
            this.contentArr.push(obj)
          })
          this.tableContent = this.contentArr
          console.log(this.contentArr)
          this.total = res.total
          this.loading = false
          resolve()
        }).catch(err => {
          this.loading = false
        })
      })
    },
    // Tag过滤筛选
    changeFilter(v) {
      this.getTableScroll(v)
    },
    getTableScroll(params) {
      const contentTables = this.$refs.tables
      this.$nextTick(()=>{
        this.tableTitle.forEach((titleItem,titleIndex)=>{
          if(params==titleItem.parameterEn) {
            let positionJump = (titleIndex+1) * 240 
            setTimeout(()=>{
              contentTables.bodyWrapper.scrollLeft = positionJump
            },300)
          }
          if(params==titleItem.pointName) {
            let positionJump = (titleIndex+1) * 240 
            setTimeout(()=>{
              contentTables.bodyWrapper.scrollLeft = positionJump
            },300)
          }
        })
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/iotReportFirst')
    }
    this.row = this.$route.params.row
    this.getLastPage()
    this.getSize()
    this.getRecordList()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('currentPage')
    sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.btn {
  float: right;
}
.search {
  padding: 10px;
}
</style>